/**
 * Contracts Addresses For:
 * 1. USDT
 * 2. USDC
 * 3. ONDO
 * 4. WRAPPED LIQUID STAKED ETHER 2.0
 * 5. WRAPPED BTC
 * 6. WRAPPED ETHER
 * 7. POLYGON MATIC ERC20
 * 8. IMMUTABLE ERC20
 */

// Contract Address Main-Net
export const USDT_ERC20_CONTRACT_ADDRESS =
  "0xdac17f958d2ee523a2206206994597c13d831ec7";
export const USDC_ERC20_CONTRACT_ADDRESS =
  "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
export const ONDO_ERC20_CONTRACT_ADDRESS =
  "0xfAbA6f8e4a5E8Ab82F62fe7C39859FA577269BE3";
export const WRAPPED_LIQUID_STAKED_ETHER_ERC20_CONTRACT_ADDRESS =
  "0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0";
export const WRAPPED_BTC_ERC20_CONTRACT_ADDRESS =
  "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599";
export const WRAPPED_ETHER_ERC20_CONTRACT_ADDRESS =
  "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
export const POLYGON_MATIC_ERC20_CONTRACT_ADDRESS =
  "0x455e53CBB86018Ac2B8092FdCd39d8444aFFC3F6";
export const IMMUTABLE_ERC20_CONTRACT_ADDRESS =
  "0xF57e7e7C23978C3cAEC3C3548E3D615c346e79fF";

// Sepolia Testnet
export const SEPOLIA_USDT_ERC20_CONTRACT_ADDRESS =
  "0xaA8E23Fb1079EA71e0a56F48a2aA51851D8433D0";
export const SEPOLIA_USDC_ERC20_CONTRACT_ADDRESS =
  "0xf08A50178dfcDe18524640EA6618a1f965821715";
export const SEPOLIA_ONDO_ERC20_CONTRACT_ADDRESS =
  "0x84180ad50276fC82e0aF381676F066471384fBFD";
export const SEPOLIA_WRAPPED_LIQUID_STAKED_ETHER_ERC20_CONTRACT_ADDRESS =
  "0x1A0CDABEE2C57C965b8BbC037671E458805DfDd5";
export const SEPOLIA_WRAPPED_BTC_ERC20_CONTRACT_ADDRESS =
  "0x083c8013229Bdb0B6824F8c6eA598BFF27b69F19";
export const SEPOLIA_WRAPPED_ETHER_ERC20_CONTRACT_ADDRESS =
  "0xb16F35c0Ae2912430DAc15764477E179D9B9EbEa";
export const SEPOLIA_POLYGON_MATIC_ERC20_CONTRACT_ADDRESS =
  "0x50FFE9e5eCD4041Ab3DCf582C2150A38AC8faf16";
export const SEPOLIA_IMMUTABLE_ERC20_CONTRACT_ADDRESS =
  "0x4A751738C82408fe0e6dbD8baf343B21FFaf4139";

