/**
 * Config File.
 */

import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { cookieStorage, createStorage } from "wagmi";
import { mainnet, sepolia, bsc, bscTestnet } from "wagmi/chains";

// Get projectId from https://cloud.reown.com
export const projectId = process.env.NEXT_PUBLIC_PROJECT_ID;

if (!projectId) throw new Error("Project ID is not defined");

// Environment check
const isProd = process.env.NODE_ENV === "production" || process.env.VERCEL_ENV === "production";

export const metadata = {
  name: "proof-register",
  description: "Proof-register Powered by WalletConnect",
  url: isProd ? "https://www.proofregister.org/" : "http://localhost:3000", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/17922993"], // Random Avatar Logo
  // icons: ["https://avatars.githubusercontent.com/u/37784886"], // Wallet Connect Logo
};

const chains = isProd ? [mainnet, bsc] : [mainnet, sepolia, bsc, bscTestnet];

export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  ssr: true,
  storage: createStorage({
    storage: cookieStorage,
  }),
});

// WORKS VERY FINE DON'T THROW AWAY!

// import { createConfig, createStorage, http } from "wagmi";
// import { mainnet, sepolia } from "wagmi/chains";
// import { injected, metaMask, safe, coinbaseWallet } from "wagmi/connectors";

// export const config = createConfig({
//   chains: [mainnet, sepolia],
//   ssr: true,
//   connectors: [injected(), metaMask(), safe(), coinbaseWallet()],
//   // storage: createStorage({ storage: window.localStorage }),
//   transports: {
//     [mainnet.id]: http(mainnet.rpcUrls.default.http[0]),
//     [sepolia.id]: http(sepolia.rpcUrls.default.http[0]),
//   },
// });
