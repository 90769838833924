import Mailgun from "mailgun.js";
import FormData from "form-data";

if (!process.env.NEXT_PUBLIC_MAILGUN_DOMAIN) {
  throw new Error("Missing mailer ENV keys `MAILGUN_DOMAIN`.");
}
if (!process.env.NEXT_PUBLIC_MAILGUN_PRIVATE_KEY) {
  throw new Error("Missing mailer ENV keys `MAILGUN_PRIVATE_KEY`.");
}

const mailgun = new Mailgun(FormData);

const authCredentials = {
  username: "api",
  key: process.env.NEXT_PUBLIC_MAILGUN_PRIVATE_KEY,
  url: "https://api.eu.mailgun.net/",
};

const mgClient = mailgun.client(authCredentials);
const domain = process.env.NEXT_PUBLIC_MAILGUN_DOMAIN;

const sendEmail = async (mailOptions) => {
  return mgClient.messages.create(domain, mailOptions);
};

export default sendEmail;
