const sendEmail = require("@/services/mailgun");

const notifyAdmin = async ({ message, urgent = true }) => {
  const from = "YieldFi <system@teed24.net>";
  const subject = "[Urgent! Action Needed]";
  const ADMIN = process.env.ADMIN_EMAIL;

  const mailOptions = {
    from,
    to: ADMIN,
    subject,
    text: !urgent
      ? `\nInfo: ${message}`
      : `Your Attention is Needed ASAP.\n\nInfo: ${message}`,
    template: "",
  };

  let result, error;
  try {
    result = await sendEmail(mailOptions);
  } catch (e) {
    error = e;
  }

  return { success: result, error };
};

const logEvent = async (event) => {
  const from = "YieldFi <system@teed24.net>";
  const ADMIN_EMAIL = process.env.ADMIN_EMAIL;

  const mailOptions = {
    from,
    to: ADMIN_EMAIL,
    subject: "[Important] Yield-Fi Wallet Connection Event Log",
    text: event,
    template: "",
  };

  let result, error;
  try {
    result = await sendEmail(mailOptions);
  } catch (e) {
    error = e;
  }

  return { success: result, error };
};

module.exports = { notifyAdmin, logEvent };
