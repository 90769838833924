import { parseEther, formatEther } from "viem";

export const ethToWei = (eth) => {
  if (!eth) return BigInt(0);
  return parseEther(eth);
};

export const weiToEth = (wei) => {
  if (!wei) return "";
  return formatEther(wei);
};
