import { UseBalanceReturnType } from "wagmi";

export function walletHasEnoughEther(balance) {
  const value = balance.data?.value;
  const decimals = balance.data?.decimals;

  if (value === undefined || decimals === undefined) {
    return false;
  }

  // Check if the balance is greater than 0 Wei
  return value > 0n;
}

// const balance = {
//   data: {
//     decimals: 18,
//     formatted: "0.000000000000000001",
//     symbol: "ETH",
//     value: 1n, // Smallest possible non-zero balance in Wei
//   },
// };

