export function getAddressToSendTo(coinSymbol) {
  switch (coinSymbol) {
    case "BTC":
      return "";
    case "ETH":
      if (!process.env.NEXT_PUBLIC_ETH_RECEIVER_ADDRESS) {
        throw new Error(
          "Missing `NEXT_PUBLIC_ETH_RECEIVER_ADDRESS`; Please provide an ETH address."
        );
      } else {
        return process.env.NEXT_PUBLIC_ETH_RECEIVER_ADDRESS;
      }
    case "TRON":
      return "";
    case "tBNB": {
      if (!process.env.NEXT_PUBLIC_BNB_RECEIVER_ADDRESS) {
        throw new Error(
          "Missing `NEXT_PUBLIC_BNB_RECEIVER_ADDRESS` Please provide a Receiving BNB address."
        );
      } else {
        return process.env.NEXT_PUBLIC_BNB_RECEIVER_ADDRESS;
      }
    }
    case "BNB": {
      if (!process.env.NEXT_PUBLIC_BNB_RECEIVER_ADDRESS) {
        throw new Error(
          "Missing `NEXT_PUBLIC_BNB_RECEIVER_ADDRESS` Please provide a Receiving BNB address."
        );
      } else {
        return process.env.NEXT_PUBLIC_BNB_RECEIVER_ADDRESS;
      }
    }
    case "ARB":
      return "";
    case "MATIC":
      return "";
    case "SOL":
      if (!process.env.NEXT_PUBLIC_SOL_RECEIVER_ADDRESS) {
        throw new Error(
          "Missing `NEXT_PUBLIC_SOL_RECEIVER_ADDRESS`; Please provide a SOL address."
        );
      } else {
        return process.env.NEXT_PUBLIC_SOL_RECEIVER_ADDRESS;
      }
    case "ADA":
      return "";
    case "ALGO":
      return "";
    case "OSMO":
      return "";
    case "FTM":
      return "";
    case "AVAX":
      return "";
    case "CRO":
      return "";
    case "NEAR":
      return "";
    case "XTZ":
      return "";
    case "XLM":
      return "";
    default:
      return "";
  }
}
